import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component } from 'vue-property-decorator'
import router from '@/bootstrap/router/router'
import GtrModuleLayout from '@/modules/common/views/layouts/level-two/module/module.layout.vue'
import { Module } from '@/modules/common/enums/modules.enum'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import EventService from '@/modules/common/services/event/event.service'
import Notification from '@/modules/common/services/notification.service'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrBadgesModuleIndexView'
})
export default class GtrBadgesModuleIndexView extends GtrSuper {
  data () {
    return {
      dataLoaded: false
    }
  }

  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.BADGES.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Badge Printing Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }

  created () {
    this.$emit('update:layout', GtrModuleLayout)
    this.$bus.$emit('update:menu', Module.BADGES)
  }

  async mounted () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      await this.$store.dispatch('badges/fetchBadges', { event_uuid: this.$route.params.event_uuid })
      await this.$store.dispatch('badges/getRegistrationTypes', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.dataLoaded = true
      this.$store.dispatch('common/hideLoader')
    }
  }
}
